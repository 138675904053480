import { Dispatch, FunctionComponent, SetStateAction } from "react";
import {
  ProgressBar,
  QuizChoice,
  Quiz,
  UnorderedList,
  QuizLastStep,
} from "@bluebottlecoffee/design-system/components";
import { CheckMark } from "@bluebottlecoffee/design-system/components/Icon/icons/CheckMark";
import { toHTML } from "@portabletext/to-html";
import { trackProductClicked } from "@chordcommerce/react-autonomy/dist/redux/actions";
import { useCart, useProduct } from "@chordcommerce/react-autonomy";
import { ShopCardPropsWithProduct } from "@bluebottlecoffee/design-system/components/ShopCard/ShopCardTypes";
import { kebabCase } from "lodash";
import { ConversionCopy } from "../pages/[region]/[lang]/product/[slug]";
import { ShippingCopy } from "../lib/sanity-schema";
import { isFeatureEnabled } from "../lib/utils/is-feature-enabled";
import useVariantPrice from "../lib/chord/hooks/components/use-variant-price";
import useVariantAvailability from "../lib/chord/hooks/components/use-variant-availability";
import { createStockRequest } from "../lib/chord/transformers/product";
import { toShopCardProps } from "../lib/transformers/shop-card";
import { toInternalUrl, toLinkProps } from "../lib/transformers/link";
import {
  toImageProps,
  toImageResponsiveProps,
} from "../lib/transformers/image";
import { QuizProjection } from "../lib/sanity/quiz-queries";
import { QuizSteps, SubscriptionQuizCopy } from "./Quiz";
import { QuizCalculatorWrapper } from "./QuizCalculatorWrapper";

interface QuizModalProps {
  currentStep: QuizSteps;
  history: QuizSteps[];
  onPrevStep: (fn: () => void) => void;
  onClose: () => void;
  onNextStep: Dispatch<SetStateAction<QuizSteps>>;
  setModalOpen?: Dispatch<SetStateAction<boolean>>;
  valueProps: QuizProjection["valueProps"];
  region: string;
  lang: string;
  shopcardCopy: {
    copy: ConversionCopy;
    shippingCopy: ShippingCopy;
  };
  subscriptionQuizCopy: SubscriptionQuizCopy;
  trackingQuiz: (step: string) => void;
}

export const QuizModal: FunctionComponent<QuizModalProps> = ({
  currentStep,
  history,
  onPrevStep,
  onNextStep,
  onClose,
  setModalOpen,
  lang,
  region,
  valueProps,
  shopcardCopy,
  subscriptionQuizCopy,
  trackingQuiz,
}) => {
  const { addToCart: chordAddToCart, addSubscription } = useCart();
  const { createStockRequest: createStockRequestChord } = useProduct();

  const addToCart = (sku: string, quantity: number) =>
    chordAddToCart({ sku, quantity });

  /** returns step number for current place in the quiz */
  const getCurrentStep = (): number => {
    if (
      currentStep._type === "choiceQuiz" &&
      currentStep.choices.filters &&
      history[history.length - 1]?._type === "choiceQuiz"
    ) {
      return 2;
    }
    if (currentStep._type === "choiceQuiz" && currentStep.choices.filters) {
      return 1;
    }
    if (currentStep._type === "choiceQuiz" && currentStep.choices.products) {
      return 3;
    }
    if (currentStep._type === "productQuiz") {
      return 4;
    }
    return 5;
  };

  if (currentStep._type === "lastStepQuiz") {
    // Last step
    const productStep = history[history.length - 1];
    const productName: string =
      productStep._type === "productQuiz" && productStep.product.name[lang];

    const announcement = currentStep.announcement.replace(
      "{{productName}}",
      `<b>${productName}</b>`,
    );

    const shopcards: ShopCardPropsWithProduct[] =
      currentStep.productRecommendations.reduce((prev, productRec) => {
        if (!productRec.forSale) {
          throw new Error(
            `The following products are no longer for sale: ${productRec.name[lang]}. Please update your selection. This issue is located in sanity/quiz document.`,
          );
        }

        const shopcard = toShopCardProps({
          data: productRec,
          lang,
          region,
          ...shopcardCopy,
        });

        return [
          ...prev,
          {
            ...shopcard,
            shippingCopy: shopcard.shippingCopy,
            product: isFeatureEnabled(process.env.NEXT_PUBLIC_PLP_ATC_ENABLED)
              ? shopcard.product
              : undefined,
            useVariantPrice,
            useVariantAvailability,
            clickTracking: () =>
              trackProductClicked(shopcard.trackShopcardData),
            addToCart,
            subscribeProduct: addSubscription,
            createStockRequest: (data) =>
              createStockRequest(
                data,
                shopcard.trackShopcardData.product,
                createStockRequestChord,
              ),
          },
        ];
      }, []);

    return (
      <QuizLastStep
        {...currentStep}
        primaryCta={{
          text: currentStep.primaryCta,
          url: toInternalUrl({
            lang,
            region,
            link: "cart",
          }),
        }}
        announcement={announcement}
        productRecommendations={shopcards}
        secondaryCta={toLinkProps(currentStep.secondaryCta, region, lang)}
      />
    );
  }

  const renderQuizModules = () => {
    switch (currentStep._type) {
      // Filter or product options
      case "choiceQuiz":
        return (
          <QuizChoice
            filters={currentStep.choices.filters?.map((filter) => ({
              text: filter.heading,
              image: toImageProps(filter.image, lang),
              onClick: () => {
                onNextStep(filter.nextStep);
                trackingQuiz(kebabCase(filter.heading));
              },
            }))}
            products={currentStep.choices.products?.map((productStep) => {
              const {
                product: { quickSubscribeCard, images, name, shopCard },
              } = productStep;
              const details1 = quickSubscribeCard.detail?.recommendedCopy[lang];

              return {
                ctaText: productStep.buttonSelectLabel,
                image: toImageResponsiveProps(images[0], lang).desktop,
                onClick: () => {
                  onNextStep(productStep);
                  trackingQuiz(kebabCase(productStep.product.name[lang]));
                },
                details1: shopCard.detail?.tagline[lang] ?? null,
                description: details1 ? toHTML(details1) : null,
                heading: name[lang],
              };
            })}
          />
        );

      case "productQuiz":
        // Product Options (size, schedule)
        return (
          <QuizCalculatorWrapper
            lang={lang}
            productQuiz={currentStep}
            subscriptionQuizCopy={subscriptionQuizCopy}
            addSubscription={addSubscription}
            onNextStep={(lastStep) => {
              onNextStep(lastStep);
              trackingQuiz(
                `last-step:${kebabCase(currentStep.product.name[lang])}`,
              );
            }}
            useVariantPrice={useVariantPrice}
          />
        );
      default:
        console.error(`Unhandled quiz step type`);
        return (
          <div>
            <p>Error: Unrecognized step type.</p>
          </div>
        );
    }
  };

  return (
    <Quiz
      bgColor="cream"
      ProgressBar={
        <ProgressBar
          totalSteps={5}
          currentStep={{
            label: currentStep.heading,
            step: getCurrentStep(),
          }}
        />
      }
      ValueProps={
        <UnorderedList
          listItems={valueProps.map((item) => ({
            text: item,
            icon: <CheckMark color="green" />,
          }))}
          bgColor="beige"
        />
      }
      backButton={() => onPrevStep(() => setModalOpen(false))}
      closeButton={() => {
        onClose();
        setModalOpen(false);
      }}
      copy={{
        heading: currentStep.heading,
        subheading: currentStep.subHeading,
        labelBackButton: "Go back",
        labelCloseButton: "Close",
      }}
    >
      {renderQuizModules()}
    </Quiz>
  );
};
