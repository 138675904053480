import { FunctionComponent, useState } from "react";
import { Modal, TwoUp } from "@bluebottlecoffee/design-system/components";
import { toTwoUpProps } from "../lib/transformers/two-up";
import {
  ChoiceQuizProjection,
  LastStepProjection,
  ProductQuizProjection,
  QuizProjection,
} from "../lib/sanity/quiz-queries";
import { QuizModal } from "./QuizModal";
import { ShippingCopy } from "../lib/sanity-schema";
import { ConversionCopy } from "../pages/[region]/[lang]/product/[slug]";

export type SubscriptionQuizCopy = {
  buttonLoadingText: string;
  calculatorSliderFirstDetail: string;
  calculatorSliderLabel: string;
  calculatorSliderSecondDetail: string;
  weRecommend: string;
};

export type QuizSteps =
  | ChoiceQuizProjection
  | ProductQuizProjection
  | LastStepProjection;

interface QuizProps extends QuizProjection {
  region: string;
  lang: string;
  shopcardCopy: {
    copy: ConversionCopy;
    shippingCopy: ShippingCopy;
  };
  subscriptionQuizCopy: SubscriptionQuizCopy;
}

export const trackQuizStepEvent = (quizName: string, stepName: string) => {
  window.analytics.track(quizName, {
    step: stepName,
  });
};

export const Quiz: FunctionComponent<QuizProps> = ({
  start,
  valueProps,
  lang,
  introduction,
  region,
  shopcardCopy,
  subscriptionQuizCopy,
}) => {
  const [currentStep, setCurrentStep] = useState<QuizSteps>(undefined);
  const [history, setHistory] = useState<QuizSteps[]>([]);
  const quizName = introduction?.title;

  const handleNextStep = (nextStep: QuizSteps) => {
    setHistory([...history, currentStep]);
    setCurrentStep(nextStep);
  };

  const handlePrevStep = (onCloseModal: () => void) => {
    const prevStep = history.pop();
    setCurrentStep(prevStep);
    setHistory([...history]);
    if (!prevStep) onCloseModal();
  };

  const handleClose = () => {
    setCurrentStep(null);
    setHistory([]);
  };

  const trackingQuiz = (step: string) => {
    trackQuizStepEvent(quizName, step);
  };

  return (
    <>
      <div id="quiz-subscription-modal" />
      <TwoUp
        {...toTwoUpProps(introduction, region, lang, null, () => {
          handleNextStep(start);
          trackingQuiz("user-joined-quiz");
        })}
      />

      <Modal
        labelledById="" // Todo: add label
        modalRootId="quiz-subscription-modal"
        onDismiss={handleClose}
        className="quiz-modal-wrapper"
        classNameMainContainer="quiz-modal-main-container"
        dismissButtonVisible={false}
        isOpen={Boolean(history.length && currentStep)}
        modalContentChildren={
          <QuizModal
            currentStep={currentStep}
            history={history}
            onClose={handleClose}
            onPrevStep={handlePrevStep}
            onNextStep={handleNextStep}
            valueProps={valueProps}
            lang={lang}
            region={region}
            shopcardCopy={shopcardCopy}
            subscriptionQuizCopy={subscriptionQuizCopy}
            trackingQuiz={trackingQuiz}
          />
        }
      />
    </>
  );
};
