import { FunctionComponent, useCallback } from "react";
import {
  QuickShopCarousel,
  QuickShopCarouselProps,
  ShopCardProps,
} from "@bluebottlecoffee/design-system/components";
import {
  ProductVariant,
  useAnalytics,
  useCart,
  useProduct,
  Product,
} from "@chordcommerce/react-autonomy";
import { AlgoliaShopCollectionProduct } from "../lib/algolia/types";
import useVariantPrice from "../lib/chord/hooks/components/use-variant-price";
import {
  QuickShopCarousel as QuickShopCarouselSchema,
  ShippingCopy,
} from "../lib/sanity-schema";
import useVariantAvailability from "../lib/chord/hooks/components/use-variant-availability";
import {
  QuickShopCarouselTransformerProps,
  toQuickShopCarouselProps,
} from "../lib/transformers/quick-shop-carousel";
import { ConversionCopy } from "../pages/[region]/[lang]/product/[slug]";

export type DereferencedQuickShopCarouselProps = Omit<
  QuickShopCarouselSchema,
  "shopCards"
> & {
  shopCards: AlgoliaShopCollectionProduct[];
};

type QuickShopCarouselWrapperProps = {
  shopcardCopy: {
    copy: ConversionCopy;
    shippingCopy: ShippingCopy;
  };
  moduleProps: DereferencedQuickShopCarouselProps;
  lang: string;
  region: string;
};

export const QuickShopCarouselWrapper: FunctionComponent<
  QuickShopCarouselWrapperProps
> = ({ shopcardCopy, moduleProps, lang, region }) => {
  const { addSubscription, addToCart: chordAddToCart } = useCart();
  const { createStockRequest } = useProduct();
  const { trackProductClicked } = useAnalytics();

  const addToCart = useCallback(
    (sku: string, quantity: number) => chordAddToCart({ sku, quantity }),
    [chordAddToCart],
  );

  const handleCreateStockRequest = async ({ email, sku }, product: Product) => {
    const productData: Product = {
      ...product,
      variant:
        product?.variant?.sku !== sku
          ? ({
              sku,
            } as ProductVariant)
          : product?.variant,
    };

    await createStockRequest({ email, product: productData });
  };

  const transformedProps: QuickShopCarouselTransformerProps =
    toQuickShopCarouselProps(shopcardCopy, moduleProps, lang, region);

  const shopCardsWithFns: ShopCardProps[] = transformedProps.shopCards.map(
    (shopCard) => ({
      ...shopCard,
      subscribeProduct: addSubscription,
      addToCart,
      useVariantAvailability,
      useVariantPrice,
      createStockRequest: (data) =>
        handleCreateStockRequest(data, shopCard.trackShopcardData.product),
      clickTracking: () => trackProductClicked(shopCard.trackShopcardData),
      shippingCopy: shopCard.shippingCopy,
    }),
  );

  const quickShopModuleProps: QuickShopCarouselProps = {
    ...transformedProps,
    shopCards: shopCardsWithFns,
  };

  return <QuickShopCarousel {...quickShopModuleProps} />;
};
