import { TwoUpCopyWithListProps } from "@bluebottlecoffee/design-system/components/modules/TwoUp/components";
import { toHTML } from "@portabletext/to-html";
import { CopyWithList } from "../sanity-schema";

export const toCopyWithList = (
  data: CopyWithList,
  lang: string,
  onClick: () => void,
): TwoUpCopyWithListProps => ({
  cta: data.cta[lang],
  description: data?.description && toHTML(data.description[lang]),
  heading: data.heading[lang],
  listItems: data.listItems.map((list) => list[lang]),
  onClick,
});
