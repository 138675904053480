import {
  Product,
  ProductVariant,
  UseProduct,
} from "@chordcommerce/react-autonomy";
import {
  AlgoliaRecommendProduct,
  AlgoliaShopCollectionProduct,
} from "../../algolia/types";
import { ConversionSchema } from "../../transformers/conversion";
import { SourceProductRec } from "../../transformers/product-recs";

export type ProductProps =
  | AlgoliaShopCollectionProduct
  | ConversionSchema
  | AlgoliaRecommendProduct
  | SourceProductRec;

export const toTrackProductProps = (
  product: ProductProps,
  lang: string,
): { product: Product } => {
  const variant = product.variants[0];
  const [image] = variant.images?.length ? variant.images : product.images;

  return {
    product: {
      metadata: {
        brand: product.brandName,
      },
      variant: {
        slug: product.slug.current,
        sku: variant.sku,
        price: (variant.price / 100).toString(),
        images: [
          {
            alt: image.desktop.altText[lang],
            type: image.desktop.source.type,
            largeUrl: image.desktop.source.url,
          },
        ],
        description: variant.label.eng,
        name: product.name.eng,
      } as ProductVariant,
    },
  };
};

export const createStockRequest = async (
  { email, sku },
  product: Product,
  createStockRequest: UseProduct["createStockRequest"],
) => {
  const productData: Product = {
    ...product,
    variant:
      product?.variant?.sku !== sku
        ? ({
            sku,
          } as ProductVariant)
        : product?.variant,
  };

  await createStockRequest({ email, product: productData });
};
